body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.43 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

[class*='Pagination-pagination'] > button.MuiButtonBase-root.MuiButton-root.MuiButton-text[class*='Pagination-button'] {
    min-width: 35px !important;
}

[class*='Pagination-pagination']
    > button.MuiButtonBase-root.MuiButton-root.MuiButton-text[class*='Pagination-button'][tabindex='-1']:not([disabled]) {
    background-color: rgba(0, 0, 0, 0.08);
}

.paginator_container > div:nth-of-type(2) > button {
    min-width: 35px !important;
}

.paginator_container > div:nth-of-type(2) > button[tabindex='-1']:not([aria-label='Previous']):not([aria-label='Next']):not([disabled]) {
    background-color: rgba(0, 0, 0, 0.08);
}

/* JSONEditor */
button.jsoneditor-format,
button.jsoneditor-compact,
button.jsoneditor-sort,
button.jsoneditor-transform,
button.jsoneditor-repair,
button.jsoneditor-undo,
button.jsoneditor-redo {
    display: none;
}

.truncate-2-lines {
    max-height: 3rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    -webkit-line-clamp: 2 !important;
    -moz-line-clamp: 2 !important;
    -ms-line-clamp: 2 !important;
    -o-line-clamp: 2 !important;
    line-clamp: 2 !important;
    -moz-box-orient: vertical !important;
    -webkit-box-orient: vertical !important;
}

.truncate-1-line {
    max-height: 1.5rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    display: -moz-box !important;
    -webkit-line-clamp: 1 !important;
    -moz-line-clamp: 1 !important;
    -ms-line-clamp: 1 !important;
    -o-line-clamp: 1 !important;
    line-clamp: 1 !important;
    -moz-box-orient: vertical !important;
    -webkit-box-orient: vertical !important;
}

.MuiButton-root {
    text-transform: none !important;
    letter-spacing: normal !important;
}

.labelCheckbox {
    font-size: 14px !important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #e8e9ec !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #e8e9ec !important;
}

.absolute {
    position: absolute !important;
}

button.MuiButtonBase-root.MuiPaginationItem-root, [class*='Pagination-pagination'] > button.MuiButtonBase-root.MuiButton-root.MuiButton-text[class*='Pagination-button'] {
    border-radius: 50% !important;
}

.chips-bg {
    color: #4D4F5C !important;
    background-color: rgba(0,0,0,.08) !important;
    border-radius: 16px !important;
    margin-right: 4px !important;
}
.small-text {
    font-size: 14px !important;
}
.padding-9 {
    padding: 9px !important
}

@keyframes loader {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
 